/**
 * 제작 : emirue
 * 날짜 : 2022/03/06
 * 내용 : 연락처
 */

import React from "react";
import {FooterContactWrapStyled} from "../../stylesheets/footer";

function FooterContact() {
  return (
    <FooterContactWrapStyled>
      <div><a href="tel:+82-70-8884-6000"><img src="/img/ico_phone.png" alt="+82-70-8884-6000" />+82-70-8884-6000</a></div>
      <div><a href="mailto:asff.Seoul@gmail.com"><img src="/img/ico_mail.png" alt="asff.Seoul@gmail.com" />asff.Seoul@gmail.com</a></div>
    </FooterContactWrapStyled>
  );
}

FooterContact.propTypes = {};
FooterContact.defaultProps = {};

export default FooterContact;
