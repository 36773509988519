/**
 * 제작 : emirue
 * 날짜 : 2022/03/06
 * 내용 : footer
 */

import React from "react";
import {FooterWrapStyled} from "../../stylesheets/footer";
import FooterSns from "./FooterSns";
import FooterTitle from "./FooterTitle";
import FooterContact from "./FooterContact";
import FooterSubtitle from "./FooterSubtitle";

function Footer() {
  return (
    <FooterWrapStyled>
      <div className="footer-container">
        <FooterSns />
        <FooterTitle />
        <FooterContact />
        <FooterSubtitle />
      </div>
    </FooterWrapStyled>
  );
}

Footer.propTypes = {};
Footer.defaultProps = {};

export default Footer;
