import styled from 'styled-components';

const HostWrapperStyled = styled.div`
  margin: 30px auto;
`;

const HostWrapStyled = styled.div`
  margin: 20px auto 0;
  display: flex;
  align-items: center;

  .host-wrap {
    margin: 0 auto;
    display: flex;
  }
  
  .host-title {
    position: relative;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-right: 20px;
    
    &.host {
      padding-top: 20px;
      &:before {
        width: 50px;
      }
    }

    &.sponsor {
      padding-top: 30px;
      &:before {
        width: 100px;
      }
    }

    &:before {
      position: absolute;
      content: '';
      width: 70px;
      height: 4px;
      background-color: #303030;
      margin: 0 auto;
    }
  }
  
  .host-img:not(:last-child) {
    margin-right: 20px;
  }

  @media all and (min-width:768px) and (max-width:1023px) {
    display: block;
    margin-left: 20px;

    .host-wrap {
      display: block;
    }
  }

  @media all and (max-width:767px) {
    display: block;
    margin-left: 20px;
    
    .host-wrap {
      display: block;
    }
  }
`;

export {
  HostWrapperStyled,
  HostWrapStyled,
};
