/**
 * 제작 : emirue
 * 날짜 : 2022/03/06
 * 내용 : 제목
 */

import React from "react";
import {CommonDividerStyled} from "../../stylesheets/common";
import {FooterTitleWrapStyled} from "../../stylesheets/footer";

function FooterTitle() {
  return (
    <FooterTitleWrapStyled>
      <h2>Asia Sustainable Finance Forum 2022</h2>
      <CommonDividerStyled />
    </FooterTitleWrapStyled>
  );
}

FooterTitle.propTypes = {};
FooterTitle.defaultProps = {};

export default React.memo(FooterTitle);
