/**
 * 제작 : emirue
 * 날짜 : 2022/03/06
 * 내용 : about
 */

import React from "react";
import {AboutWrapStyled} from "../../stylesheets/about";
import {CommonDividerStyled} from "../../stylesheets/common";
import {useNav} from "../../components/customHooks/useNav";
import Overview from "./Overview";

function About() {
  const aboutRef = useNav('ABOUT');

  return (
    <AboutWrapStyled ref={aboutRef} id="aboutContainer">
      <div className="container">
        <div className="row">
          <div className="col text-center about-title">
            <h1>Asia Sustainable Finance Forum 2022</h1>
            <h2>Next Steps for Climate Innovation</h2>
            <CommonDividerStyled />
          </div>
        </div>
        <div className="row about-contents">
          <div className="col">
            <p>
              <span className="font-weight-bold">The Asia Sustainable Finance Forum 2022 is the inaugural event hosted by the Financial Services Commission (FSC) of the Republic of Korea and co-organized by the consortium of Stanford University, Korea Advanced Institute of Science and Technology (KAIST), and Korea Development Bank Group (KDB).</span> The event, and its subsequent events, aim to convene academic scholars, industry practitioners, and policymakers in Asia and call for integrated actions toward the global net-zero transition.<br/>
              There is significant and growing interest in the topics of sustainable finance and investment. Asia, in specific, confronts severe issues with the transitioning carbon-reliant economies while not compromising the trajectory of rapid economic growth. Despite a global agreement on the 1.5C target and increasing net-zero pledges globally, there is a lack of consensus-building and implementation plans in Asia. At this juncture, academic research should play a significant role in filling this gap through close collaboration with major stakeholders.<br/>
              The consortium presents a novel form of global joint academia-industry initiative that focuses on a new paradigm integrating technology, finance, and policy innovation in accelerating the net-zero transition in Asia. This transition will require intelligent market design, sophisticated sustainability assessment tools, and new investment and business models. Systems that successfully navigate these untapped frontiers will generate outsized returns by positioning themselves to be more efficient, flexible, resilient, and environmentally sustainable. High-quality, rigorous academic research explored and developed by the consortium can demonstrate opportunities under each and across technology, finance, and policy sectors that will profoundly contribute to this new paradigm shift.<br/>
              The collaborative works will help align the financial system with global sustainability, meeting the Paris Climate Change Agreement and the Sustainable Development Goals, and create net-zero pathways appropriate to Asian contexts.
            </p>
          </div>
        </div>
        <div className="row about-overview-wrapper">
          <div className="col">
            <h1>OVERVIEW</h1>
            <CommonDividerStyled />

            <Overview />
          </div>
        </div>
      </div>
    </AboutWrapStyled>
  );
}

About.propTypes = {};
About.defaultProps = {};

export default About;
