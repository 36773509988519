/**
 * 제작 : emirue
 * 날짜 : 2022/03/06
 * 내용 : 중간 메뉴
 */

import React from "react";
import {MiddleMenuWrapStyled} from "../../stylesheets/middle-menu";
import {navLinks} from "../../components/nav/navLinks";
import NavLink from "../../components/nav/NavLink";

function MiddleMenu() {
  return (
    <MiddleMenuWrapStyled>
      <div className="container d-flex align-items-center middle-menu-wrapper">
        <div className="middle-menu-none" />
        <nav className="navbar">
          <ul>
            {navLinks.map(({ navLinkId, scrollToId }, idx) => (
              <NavLink key={idx} navLinkId={navLinkId} scrollToId={scrollToId} />
            ))}
          </ul>
        </nav>
      </div>
    </MiddleMenuWrapStyled>
  );
}

MiddleMenu.propTypes = {};
MiddleMenu.defaultProps = {};

export default MiddleMenu;
