import styled from 'styled-components';

const MainBannerWrapStyled = styled.section`
  width: 100%;
  height: 90vh;
  background: url('/img/main_banner_new.png') no-repeat center center;
  background-color: #23ac3a !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 1500px;

  @media all and (min-width:768px) and (max-width:1023px) {
    background-size: 1000px;
  }

  @media all and (max-width:767px) {
    background-size: 440px;
    height: 40vh;
  }
`;


export {
  MainBannerWrapStyled,
};
