/**
 * 제작 : emirue
 * 날짜 : 2022/03/06
 * 내용 : 프로그램
 */

import React from "react";
import {ProgramWrapStyled} from "../../stylesheets/program";
import {CommonDividerStyled} from "../../stylesheets/common";
import {useNav} from "../../components/customHooks/useNav";

function Program() {
  const programRef = useNav('PROGRAM');

  return (
    <ProgramWrapStyled ref={programRef} id="programContainer">
      <div className="container">
        <div className="row">
          <div className="col program-title">
            <h1>PROGRAM</h1>
            <CommonDividerStyled />
          </div>
        </div>
        <div className="row">
          <div className="col text-center program-table-wrap">
            <div className="program-table-wrapper">
              <table className="table program-table">
                <thead>
                <tr>
                  <th>TIME (GMT+9)</th>
                  <th colSpan="2">PROGRAM</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>09:30 - 09:35</td>
                  <td>Opening<br />Remarks</td>
                  <td><span className="bold">LEE Dong Gull</span> Chairman, Korea Development Bank (KDB), Republic of Korea</td>
                </tr>
                <tr>
                  <td className="pt-54">09:35 - 09:40</td>
                  <td className="pt-40">Congratulatory<br />Remarks</td>
                  <td>
                    <span className="bold">YUN Jaeok</span> Chairman of the National Policy Committee, Republic of Korea<br />
                    <span className="bold">YUN Sun-jin</span> Co-Chairperson of 2050 Carbon Neutrality Commission, Republic of Korea & Graduate School of Environmental Studies of Seoul National University
                  </td>
                </tr>
                <tr>
                  <td className="pt-40">09:40 - 09:50</td>
                  <td className="pt-30">Welcome<br />Remarks</td>
                  <td>
                    <span className="bold">KOH Seungbeom</span> Chairman, Financial Services Commission, Republic of Korea<br/>
                    <span className="bold">LEE Kwang-hyung</span> President, KAIST<br/>
                    <span className="bold">Karen Kearney</span> Treasurer, Stanford University
                  </td>
                </tr>
                <tr>
                  <td className="pt-30">09:50 - 10:10</td>
                  <td rowSpan="2" className="pt-100">Keynote<br />Speech</td>
                  <td>
                    <span className="bold">Mark Carney</span> UN Special Envoy on Climate Action and Finance, Former governor of the Bank of England<br/>
                    <span className="bold">Manuela V. Ferro</span> Vice President, East Asia and Pacific (EAP), World Bank
                  </td>
                </tr>
                <tr>
                  <td className="pt-40">10:10 - 10:25</td>
                  <td>
                    &quot;<span className="bold">Net Zero Pathways</span>&quot;<br/>
                    <span className="bold">Thomas Heller</span> Charles and Nadine Shelton Professor of International Legal Studies (Emeritus) at Stanford University Law School, Faculty Director of the Sustainable Finance Initiative (SFI) and the Steyer-Taylor Center for Energy Policy and Finance at Stanford
                  </td>
                </tr>
                <tr>
                  <th colSpan="3" className="program-th">
                    Session 1. Net Zero Landscape
                  </th>
                </tr>
                <tr>
                  <td rowSpan="6" className="pt-200">10:25 - 11:10</td>
                  <td className="pt-30">Moderator</td>
                  <td><span className="bold">Alicia Seiger</span> Managing Director of Stanford Sustainable Finance Initiative (SFI) and the Steyer-Taylor Center for Energy Policy and Finance at Stanford</td>
                </tr>
                <tr>
                  <td rowSpan="5" className="pt-180">Panelists</td>
                  <td><span className="bold">JUNG In-Sub</span> CEO of Hanwha Energy Corporation</td>
                </tr>
                <tr>
                  <td><span className="bold">YOO Yeon-chul</span> Former Ambassador for Climate Change of the Ministry of Foreign Affairs, Republic of Korea</td>
                </tr>
                <tr>
                  <td><span className="bold">Michał Kurtyka</span> Former Minister of Climate and Environment in Poland & UNFCCC COP24 President</td>
                </tr>
                <tr>
                  <td><span className="bold">Ali Izadi</span> Head of APAC Research at BloombergNEF</td>
                </tr>
                <tr>
                  <td><span className="bold">John Weyant</span> Professor of Management Science and Engineering & Senior Fellow at the Precourt Institute for Energy, Stanford University</td>
                </tr>
                <tr>
                  <th colSpan="3" className="program-th">
                    Session 2. Sustainability at Leading Edge of Technology
                  </th>
                </tr>
                <tr>
                  <td rowSpan="7" className="pt-270">11:10 - 12:25</td>
                  <td className="pt-30">Moderator</td>
                  <td>
                    <span className="bold">Michael Lepech</span> Professor of Civil and Environmental Engineering, Faculty Director of Stanford Center at the Incheon Global Campus (SCIGC), and Senior Fellow at the Woods Institute for the Environment at Stanford University
                  </td>
                </tr>
                <tr>
                  <td className="pt-30">Presenter1</td>
                  <td><span className="bold">Yi Cui</span> Director, Precourt Institute for Energy & Professor of Materials Science at Stanford University</td>
                </tr>
                <tr>
                  <td className="pt-30">Presenter2</td>
                  <td><span className="bold">YIM Man-Sung</span> Professor of the Department of Nuclear and Quantum Engineering, KAIST</td>
                </tr>
                <tr>
                  <td rowSpan="4" className="pt-120">Panelists</td>
                  <td><span className="bold">GIM Seong Gon </span> Chairman of CS Wind Corporation</td>
                </tr>
                <tr>
                  <td><span className="bold">Zach Jones</span> CEO of C-Zero</td>
                </tr>
                <tr>
                  <td><span className="bold">KIM Nok Young</span> Head of Carbon Neutral Center, Korea Chamber of Commerce and Industry (KCCI)</td>
                </tr>
                <tr>
                  <td>
                    <span className="bold">JUNG Hee-Tae</span> Chair Professor in Chemical and Biomolecular Engineering, KAIST & Director of KAIST Institute for Nanocentury
                  </td>
                </tr>
                <tr className="program-tr-break-time">
                  <td>12:25 - 13:25</td>
                  <td colSpan="2" className="text-center">LUNCH</td>
                </tr>
                <tr>
                  <td className="pt-40">13:25 - 13:35</td>
                  <td className="pt-40">Special Talk</td>
                  <td>
                    <span className="bold">LEE Dong Gull</span> Chairman, Korea Development Bank (KDB), Republic of Korea<br/>
                    <span className="bold">Mark Carney</span> UN Special Envoy on Climate Action and Finance, Former governor of the Bank of England
                  </td>
                </tr>
                <tr>
                  <th colSpan="3" className="program-th">
                    Session 3. New Asset Class
                  </th>
                </tr>
                <tr>
                  <td rowSpan="7" className="pt-270">13:35 - 14:50</td>
                  <td className="pt-40">Moderator</td>
                  <td>
                    <span className="bold">IN Soh Young</span> Financial Innovation Lead of the Sustainable Finance Initiative (SFI) and Research Director of Stanford research center at the Incheon Global Campus (SCIGC)
                  </td>
                </tr>
                <tr>
                  <td className="pt-30">Presenter1</td>
                  <td>
                    <span className="bold">Ashby Monk</span> Executive Director, Stanford Research Initiative on Long Term Investing (LTI)
                  </td>
                </tr>
                <tr>
                  <td className="pt-30">Presenter2</td>
                  <td><span className="bold">Michael Sheren</span> Senior Advisor, Bank of England (BOE) & Co-Chair of the G20 Sustainable Finance Study Group</td>
                </tr>
                <tr>
                  <td rowSpan="4" className="pt-120">Panelists</td>
                  <td><span className="bold">LEE Hoon</span> Head of Investment Strategy & Innovation Division, Korea Investment Corporation (KIC)</td>
                </tr>
                <tr>
                  <td><span className="bold">Henry Gonzalez</span> Deputy Executive Director a.i., Green Climate Fund (GCF)</td>
                </tr>
                <tr>
                  <td>
                    <span className="bold">AHN Wook-sang</span> General Manager of ESG·New Deal Planning Department, Korea Development Bank (KDB)
                  </td>
                </tr>
                <tr>
                  <td><span className="bold">Stella Saris Chow</span> Head of Sustainable Finance, International, ANZ</td>
                </tr>
                <tr className="program-tr-break-time">
                  <td>14:50 - 15:00</td>
                  <td colSpan="2" className="text-center">BREAK TIME</td>
                </tr>
                <tr>
                  <th colSpan="3" className="program-th">
                    Session 4. Financial Stability and Climate Risk Management
                  </th>
                </tr>
                <tr>
                  <td rowSpan="6" className="pt-270">15:00 - 16:05</td>
                  <td className="pt-30">Moderator</td>
                  <td><span className="bold">EOM Jiyong</span> Director of KAIST Center for Sustainable Development (CSD) & Associate Professor of the School of Business & Technology Management at KAIST</td>
                </tr>
                <tr>
                  <td className="pt-30">Presenter1</td>
                  <td>
                    <span className="bold">David Nelson</span> Senior Director, Climate and Resilience Hub at Willis Towers Watson (WTW)
                  </td>
                </tr>
                <tr>
                  <td className="pt-30">Presenter2</td>
                  <td>
                    <span className="bold">KIM Jeayoon</span> Economist in the Financial Stability Department, Bank of Korea (BOK)
                  </td>
                </tr>
                <tr>
                  <td rowSpan="3" className="pt-120">Panelists</td>
                  <td><span className="bold">Ben Caldecott</span> Founding Director, UK Centre for Greening Finance and Investment (CGFI) & Professor of the Oxford Sustainable Finance Programme, University of Oxford</td>
                </tr>
                <tr>
                  <td><span className="bold">Jacob Gyntelberg</span> Director of Economic and Risk Analysis Department, European Banking Authority (EBA)</td>
                </tr>
                <tr>
                  <td><span className="bold">HWANG Jaehak</span> Senior Manager, Sustainable Finance Team, Financial Supervisory Service (FSS), Republic of Korea</td>
                </tr>
                <tr className="program-tr-break-time">
                  <td>16:05 - 16:15</td>
                  <td colSpan="2" className="text-center">BREAK TIME</td>
                </tr>
                <tr>
                  <th colSpan="3" className="program-th">
                    Session 5. Successful Practices and Strategies for Institutional Transformation in the Asia-Pacific
                  </th>
                </tr>
                <tr>
                  <td rowSpan="5" className="pt-200">16:15 - 16:55</td>
                  <td className="pt-30">Moderator</td>
                  <td><span className="bold">PARK Kwangwoo</span> Professor of Finance & Head of the Graduate School of Finance, KAIST</td>
                </tr>
                <tr>
                  <td className="pt-154" rowSpan="4">Panelists</td>
                  <td>
                    <span className="bold">Helena McLeod</span> Deputy Director-General and Head of the Green Growth Planning & Implementation (GGP&I) Division, Global Green Growth Institute (GGGI)
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="bold">Bruno Carrasco</span> Director General & Chief Compliance Officer, Sustainable Development and Climate Change Department, Asian Development Bank (ADB)
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="bold">HYUN Suk</span> Associate Professor, Graduate School of Environmental Finance, Yonsei University
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="bold">Yuhua AN</span> Professor of Finance, Graduate School of China, SungKyunKwan University
                  </td>
                </tr>
                <tr>
                  <td className="pt-40">16:55 - 17:00</td>
                  <td className="pt-30">Closing<br />Remarks</td>
                  <td>
                    <span className="bold">Michael Lepech</span> Professor of Civil and Environmental Engineering, Faculty Director of Stanford Center at the Incheon Global Campus (SCIGC), and Senior Fellow at the Woods Institute for the Environment at Stanford University
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ProgramWrapStyled>
  );
}

Program.propTypes = {};
Program.defaultProps = {};

export default Program;
