/**
 * 제작 : emirue
 * 날짜 : 2022/03/10
 * 내용 :
 */

import React from "react";

function Overview() {
  return (
    <div className="about-overview-table-wrapper">
      <div className="about-overview-table">
        <table className="table">
          <tbody>
          <tr>
            <th>TITLE</th>
            <td>Asia Sustainable Finance Forum 2022 (ASFF 2022)</td>
          </tr>
          <tr>
            <th>THEME</th>
            <td>Next Steps for Climate Innovation</td>
          </tr>
          <tr>
            <th className="pt-3">DATE</th>
            <td>March 24 (Thu), 2022 | 09:30-17:00(GMT+9) Seoul<br/>17:30(PST, Mar 23) | 00:30(GMT) | 01:30(CET)</td>
          </tr>
          <tr>
            <th>VENUE</th>
            <td>Park Ballroom (5F) Conrad Hotel, Seoul & Online</td>
          </tr>
          <tr>
            <th>HOST</th>
            <td>Financial Services Commission (FSC), Republic of Korea</td>
          </tr>
          <tr>
            <th className="pt-3">CO-ORGANIZER</th>
            <td>Stanford Center at the Incheon Global Campus (SCIGC),<br/>KAIST Center for Sustainable Development (CSD)</td>
          </tr>
          <tr>
            <th className="pt-3">SPONSORS</th>
            <td>Korea Development Bank (KDB), KDB Capital,<br/>KDB Infrastructure Investments Asset Management, KDB Investment</td>
          </tr>
          <tr>
            <th>FORMAT</th>
            <td>Hybrid Event with YouTube Live Streaming (Korean/English Channels)</td>
          </tr>
          <tr>
            <th>LANGUAGE</th>
            <td>Korean-English simultaneous interpretation will be provided</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

Overview.propTypes = {};
Overview.defaultProps = {};

export default Overview;
