/**
 * 제작 : emirue
 * 날짜 : 2022/03/09
 * 내용 : nav link
 */

import React, {useContext} from "react";
import {NavContext} from "../context/NavContext";
import classNames from "classnames";

const NavLink = ({ navLinkId, scrollToId }) => {
  const { activeNavLinkId, setActiveNavLinkId } = useContext(NavContext);

  const handleClick = () => {
    setActiveNavLinkId(navLinkId);
    document.getElementById(scrollToId).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <li onClick={handleClick} className={classNames({ active: activeNavLinkId === navLinkId })}><span id={navLinkId} className={classNames('nav-link', { active: activeNavLinkId === navLinkId })}>{navLinkId}</span></li>
  );
};

export default NavLink;