/**
 * 제작 : emirue
 * 날짜 : 2022/03/09
 * 내용 : useNav custom hooks
 */

import React, {useContext, useEffect, useRef} from "react";
import {NavContext} from "../context/NavContext";
import {useOnScreen} from "./useOnScreen";

export const useNav = navLinkId => {
  const ref = useRef(null);

  const { setActiveNavLinkId } = useContext(NavContext);

  const isOnScreen = useOnScreen(ref);

  useEffect(() => {
    if (isOnScreen) {
      setActiveNavLinkId(navLinkId);
    }
  }, [isOnScreen, setActiveNavLinkId, navLinkId]);

  return ref;
};
