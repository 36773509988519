/**
 * 제작 : emirue
 * 날짜 : 2022/03/16
 * 내용 : 추가정보
 */

import React from "react";
import {FooterSubtitleWrapStyled} from "../../stylesheets/footer";

function FooterSubtitle() {
  return (
    <FooterSubtitleWrapStyled>
      <span className="footer-subtitle">ASFF 2022 사무국 ㈜에이커스</span> <a href="mailto:spring@acres.co.kr">spring@acres.co.kr</a>
    </FooterSubtitleWrapStyled>
  );
}

export default FooterSubtitle;
