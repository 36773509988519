/**
 * 제작 : emirue
 * 날짜 : 2022/03/06
 * 내용 : 강연자
 */

import React from "react";
import {SpeakersWrapStyled} from "../../stylesheets/speakers";
import {CommonDividerStyled} from "../../stylesheets/common";
import speakerList from "./speakers/speakerList";
import SpeakersItem from "./speakers/SpeakersItem";
import {useNav} from "../../components/customHooks/useNav";

function Speakers() {
  const speakersRef = useNav('SPEAKERS');

  return (
    <SpeakersWrapStyled ref={speakersRef} id="speakersContainer">
      <div className="container">
        <div className="row">
          <div className="col speaker-title">
            <h1>SPEAKERS</h1>
            <CommonDividerStyled />
            <p className="pt-3">Click the photos for speakers’ bio in detail.</p>
          </div>
        </div>
        <div className="row speaker-list-wrap">
          <div className="col">
            <ul className="speaker-list">
              {speakerList.map((_item, _index) => (
                <SpeakersItem item={_item} key={`speaker-list-${_index}`} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </SpeakersWrapStyled>
  );
}

Speakers.propTypes = {};
Speakers.defaultProps = {};

export default Speakers;
