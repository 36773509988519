/**
 * 제작 : emirue
 * 날짜 : 2022/03/07
 * 내용 :
 */

import React from "react";
import Host from "./Host";
import Sponsors from "./Sponsors";
import {HostWrapperStyled} from "../stylesheets/host";

function HostWrap() {
  return (
    <HostWrapperStyled className="container">
      <div className="row">
        <div className="col">
          <Host />
          <Sponsors />
        </div>
      </div>
    </HostWrapperStyled>
  );
}

HostWrap.propTypes = {};
HostWrap.defaultProps = {};

export default HostWrap;
