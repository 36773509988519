/**
 * 제작 : emirue
 * 날짜 : 2022/03/07
 * 내용 : host
 */

import React from "react";
import {HostWrapStyled} from "../stylesheets/host";

function Host() {
  return (
    <HostWrapStyled>
      <div className="host-wrap">
        <div className="host-title host">
          Host
        </div>
        <div className="host-img">
          <img src="/img/host/host01.jpg" alt="host"/>
        </div>
        <div className="host-img">
          <img src="/img/host/host02-1.png" alt="host"/>
        </div>
        <div className="host-img">
          <img src="/img/host/host03.jpg" alt="host"/>
        </div>
        <div className="host-img">
          <img src="/img/host/host04.jpg" alt="host"/>
        </div>
      </div>
    </HostWrapStyled>
  );
}

Host.propTypes = {};
Host.defaultProps = {};

export default Host;
