/**
 * 제작 : emirue
 * 날짜 : 2022/03/06
 * 내용 : header
 */

import React from "react";
import {HeaderWrapStyled} from "../../stylesheets/header";
import {navLinks} from "../../components/nav/navLinks";
import NavLink from "../../components/nav/NavLink";
import {useNav} from "../../components/customHooks/useNav";

function Header() {
  const headerRef = useNav('HEADER');

  return (
    <HeaderWrapStyled ref={headerRef} id="header" className="fixed-top">
      <div className="container d-flex align-items-center">
        <h1 className="logo me-auto"><a href="/" className="d-none">Asian Sustainable Finance Forum 2022</a></h1>
        <nav id="navbar" className="navbar">
          <ul>
            {navLinks.map(({ navLinkId, scrollToId }, idx) => (
              <NavLink key={idx} navLinkId={navLinkId} scrollToId={scrollToId} />
              ))}
          </ul>
        </nav>
      </div>
    </HeaderWrapStyled>
  );
}

Header.propTypes = {};
Header.defaultProps = {};

export default Header;
