import styled from 'styled-components';

const HeaderWrapStyled = styled.header`
  background-color: #23ac3a;
  
  nav.navbar {
    ul {
      li {
        cursor: pointer;
        position: relative;
        
        span.nav-link {
          color: #fff;
        }
        
        &.active {
          font-weight: bold;
          
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #fff;
          }
        }
      }

      @media all and (min-width:768px) and (max-width:1023px) {
        display: flex !important;
      }
      @media all and (max-width:767px) {
        display: flex !important;
      }
    }
  }
`;


export {
  HeaderWrapStyled,
};
