import Header from "./containers/main/Header";
import MainBanner from "./containers/main/MainBanner";
import MiddleMenu from "./containers/main/MiddleMenu";
import About from "./containers/main/About";
import Footer from "./components/footer/Footer";
import Program from "./containers/main/Program";
import Speakers from "./containers/main/Speakers";
import HostWrap from "./components/HostWrap";
import NavProvider from "./components/context/NavContext";
import ButtonRegister from "./components/buttons/ButtonRegister";

function App() {
  return (
    <>
      <NavProvider>
        <Header />
        <MainBanner />
        <main id="main">
          <MiddleMenu />
          <About />
          <ButtonRegister />
          <HostWrap />
          <Program />
          <Speakers />
        </main>
        <Footer />
      </NavProvider>
    </>
  );
}

export default App;
