/**
 * 제작 : emirue
 * 날짜 : 2022/03/07
 * 내용 : 스폰서
 */

import React from "react";
import {HostWrapStyled} from "../stylesheets/host";

function Sponsors() {
  return (
    <HostWrapStyled>
      <div className="host-wrap">
        <div className="host-title sponsor">Sponsors</div>
        <div className="host-img pt-4">
          <img src="/img/sponsors/sponsors01.jpg" alt="host"/>
        </div>
        <div className="host-img pt-4">
          <img src="/img/sponsors/sponsors02.jpg" alt="host"/>
        </div>
        <div className="host-img pt-4">
          <img src="/img/sponsors/sponsors03.jpg" alt="host"/>
        </div>
        <div className="host-img pt-4">
          <img src="/img/sponsors/sponsors04.jpg" alt="host"/>
        </div>
      </div>
    </HostWrapStyled>
  );
}

Sponsors.propTypes = {};
Sponsors.defaultProps = {};

export default Sponsors;
