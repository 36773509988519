import styled from 'styled-components';

const ProgramWrapStyled = styled.section`
  background-color: #fff;
  padding: 100px 0;

  .program-title {
    text-align: center;
    
    h1 {
      font-size: 2.5rem;
      font-weight: bold;
    }
  }
  
  .program-table-wrap {
    padding-top: 50px;
    
    .program-table-wrapper {
      width: 100%;
      margin: 0 auto;
      overflow: auto;

      @media all and (min-width:1024px) {
        width: 90%;
      }

      .program-table {
        width: 100%;
        thead {
          tr {
            th {
              background-color: #070707;
              color: #fff;
              font-size: 2rem;
              font-weight: bold;
              border: 1px solid #d8d8d8;
              
              &:first-child {
                width: 26%;
              }

              @media all and (max-width:767px) {
                font-size: 1rem !important;
              }
            }
          }
        }
        
        tbody {
          tr {
            .pt-10 {
              padding-top: 10px !important;
            }
            .pt-20 {
              padding-top: 20px !important;
            }
            .pt-30 {
              padding-top: 30px !important;
            }
            .pt-40 {
              padding-top: 40px !important;
            }
            .pt-50 {
              padding-top: 50px !important;
            }
            .pt-54 {
              padding-top: 54px !important;
            }
            .pt-60 {
              padding-top: 60px !important;
            }
            .pt-68 {
              padding-top: 68px !important;
            }
            .pt-96 {
              padding-top: 96px !important;
            }
            .pt-100 {
              padding-top: 100px !important;
            }
            .pt-120 {
              padding-top: 120px !important;
            }
            .pt-154 {
              padding-top: 154px !important;
            }
            .pt-180 {
              padding-top: 180px !important;
            }
            .pt-200 {
              padding-top: 200px !important;
            }
            .pt-270 {
              padding-top: 270px !important;
            }
            
            &.program-tr-break-time {
              td {
                font-size: 1.25rem;
                background-color: #516475;
                color: #fff;
              }
            }
            
            td {
              border: 1px solid #d8d8d8;
              font-weight: 500;
              
              .bold {
                font-weight: bold;
              }
              @media all and (max-width:767px) {
                font-size: 0.75rem !important;
              }
            }
            td:first-child {
              padding-top: 20px; 
            }
            td:last-child {
              width: 54%;
              text-align: left;
              padding: 20px;
            }
            
            th {
              &.program-th {
                padding: 20px;
                background-color: #41493c;
                color: #fff;
                font-size: 2rem;
                font-weight: bold;
                
                @media all and (max-width:767px) {
                  font-size: 1.25rem !important;
                }
              }
            }
          }
        }
      }
    }
  }
`;


export {
  ProgramWrapStyled,
};
