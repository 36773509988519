import styled from 'styled-components';

const ButtonWrapStyled = styled.div`
  text-align: center;
  
  .btn {
    font-size: 2rem !important;
  }
  
  .subtitle {
    font-size: 1.25rem;
  }

  @media all and (max-width:767px) {
    .btn {
      font-size: 1.5rem !important;
    }

    .subtitle {
      font-size: 0.75rem;
    }
  }
`;


export {
  ButtonWrapStyled,
};
