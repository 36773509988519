/**
 * 제작 : emirue
 * 날짜 : 2022/03/11
 * 내용 : 구글 문서 작성
 */

import React from "react";
import {ButtonWrapStyled} from "../../stylesheets/buttons";

function ButtonRegister() {
  return (
    <ButtonWrapStyled className="text-center">
      <a href="https://docs.google.com/forms/d/115YtzsO6jGPdqIYxksXMS2M6K313pTSxrVtImcpaZVw/edit" target="_blank" className="btn btn-primary h2" rel="noreferrer">Register for the Webinar</a>
      <p className="subtitle">We will send the URL for the Live Streaming via email.</p>
    </ButtonWrapStyled>
  );
}

ButtonRegister.propTypes = {};
ButtonRegister.defaultProps = {};

export default ButtonRegister;
