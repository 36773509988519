import styled from 'styled-components';

const MiddleMenuWrapStyled = styled.div`
  width: 100%;
  height: 80px;
  background-color: #f0f0f0;
  
  .middle-menu-wrapper {
    padding: 15px 0;
    
    .middle-menu-none {
      display: block;
      padding: 0;
      line-height: 1;
      letter-spacing: 2px;
      margin-right: auto;
    }
    
    nav.navbar {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      
      ul {
        li {
          cursor: pointer;
          width: 100%;
          span {
            font-weight: bold;
            padding: 0.5rem 1rem;
            color: #070707;
            text-align: center;
            
            &.active {
              border-bottom: 1px solid #070707;
            }
          }
        }

        @media all and (min-width:768px) and (max-width:1023px) {
          display: flex !important;
        }
        @media all and (max-width:767px) {
          display: flex !important;
        }
      }
    }
  }
`;


export {
  MiddleMenuWrapStyled,
};
