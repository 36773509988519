/**
 * 제작 : emirue
 * 날짜 : 2022/03/06
 * 내용 : banner
 */

import React from "react";
import {MainBannerWrapStyled} from "../../stylesheets/main-banner";

function MainBanner() {
  return (
    <MainBannerWrapStyled className="d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
               data-aos="fade-up" data-aos-delay="200">
            <h1>Asia Sustainable Finance Forum 2022</h1>
            <h2>Next Steps for Climate Innovation</h2>
            <h3>March 24(Thu), 2022 | 09:30-17:00 Seoul</h3>
          </div>
        </div>
      </div>

    </MainBannerWrapStyled>
  );
}

MainBanner.propTypes = {};
MainBanner.defaultProps = {};

export default MainBanner;
