import styled from 'styled-components';

const FooterWrapStyled = styled.footer`
  background-color: #f2f2f2;
  height: 376px;
  text-align: center;
  padding: 50px 10px;
  
  .footer-container {
    max-width: 600px;
    margin: 0 auto;
  }
`;

const FooterSnsWrapStyled = styled.div`
  ul {
    margin: 0 auto;
    text-align: center;
    max-width: 280px;
    
    li {
      float: left;
      list-style-type: none;
      line-height: 44px;
      
      &:not(:last-child) {
        margin-right: 48px;
      }
    }
  }
`;

const FooterTitleWrapStyled = styled.div`
  clear: both;
  padding-top: 50px;
  
  @media all and (max-width:767px) {
    h2 {
      font-size: 1.3rem;
    }
  }
`;

const FooterContactWrapStyled = styled.div`
  padding-top: 50px;
  text-align: center;
  font-size: 22px;
  
  a {
    color: #000;
    img {
      margin-right: 10px;
    }
  }
`;

const FooterSubtitleWrapStyled = styled.div`
  padding-top: 10px;
  text-align: center;
  font-size: 22px;
  line-height: 1;
  
  a {
    color: #000;
  }
`;

export {
  FooterWrapStyled,
  FooterSnsWrapStyled,
  FooterTitleWrapStyled,
  FooterContactWrapStyled,
  FooterSubtitleWrapStyled,
};
