/**
 * 제작 : emirue
 * 날짜 : 2022/03/06
 * 내용 : 강연자 아이템
 */

import React, {useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ReactModal from "react-modal";
import SpeakerDetail from "./SpeakerDetail";

const customStyles = {
  content: {
    zIndex: "9999",
  },
};

function SpeakersItem({ item }) {
  const [canShowDesc, setCanShowDesc] = useState(false);
  const [canShowModal, setCanShowModal] = useState(false);

  /**
   * mouse over
   */
  function handleMouseEnter() {
    setCanShowDesc(true);
  }

  /**
   * mouse out
   */
  function handleMouseLeave() {
    setCanShowDesc(false);
  }

  /**
   * open modal
   * @param e
   */
  function handleOpenModal(e) {
    e.preventDefault();
    setCanShowModal(true);
  }

  /**
   * close modal
   */
  function handleCloseModal() {
    setCanShowModal(false);
  }

  return (
    <>
      <li onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleOpenModal}>
        <img src={item.url} alt={item.name} />
        <div className={classNames('speaker-item-content', { show: canShowDesc })}>
          <div className="speaker-item-content-wrap">
            <h3>{item.name}</h3>
            <p>{item.title}</p>
            <div className="speaker-item-subtitle">{item.subtitle}</div>
          </div>
        </div>
      </li>
      {canShowModal && <ReactModal style={customStyles} isOpen={canShowModal}><SpeakerDetail item={item} onClose={handleCloseModal} /></ReactModal>}
    </>
  );
}

SpeakersItem.propTypes = {
  item: PropTypes.object.isRequired,
};
SpeakersItem.defaultProps = {};

export default SpeakersItem;
