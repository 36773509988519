/**
 * 제작 : emirue
 * 날짜 : 2022/03/09
 * 내용 : nav context
 */

import React, {useState} from "react";

export const NavContext = React.createContext();

function NavProvider({ children }) {
  const [activeNavLinkId, setActiveNavLinkId] = useState('');

  const providerValue = {
    activeNavLinkId,
    setActiveNavLinkId,
  };

  return (
    <NavContext.Provider value={providerValue}>{children}</NavContext.Provider>
  );
}

export default NavProvider;
