/**
 * 제작 : emirue
 * 날짜 : 2022/03/06
 * 내용 : sns
 */

import React from "react";
import {FooterSnsWrapStyled} from "../../stylesheets/footer";

function FooterSns() {
  return (
    <FooterSnsWrapStyled>
      <ul>
        <li><a href="https://www.linkedin.com/company/asian-sustainable-finance-forum-2022" target="_blank" rel="noreferrer"><img src="/img/sns01.png" alt="Linkedin" /></a></li>
        <li><a href="https://www.facebook.com/ASFF2022/?ref=page_internal" target="_blank" rel="noreferrer"><img src="/img/sns02.png" alt="Facebook" /></a></li>
        <li><a href="https://www.youtube.com/channel/UCdpSOJLBW3AZUihuXyluvmA" target="_blank" rel="noreferrer"><img src="/img/sns03.png" alt="YouTube" /></a></li>
      </ul>
    </FooterSnsWrapStyled>
  );
}

FooterSns.propTypes = {};
FooterSns.defaultProps = {};

export default FooterSns;
