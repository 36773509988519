import styled from 'styled-components';

const SpeakersWrapStyled = styled.section`
  background-color: #fff;
  padding: 100px 0;

  .speaker-title {
    text-align: center;

    h1 {
      font-size: 2.5rem;
      font-weight: bold;
    }
  }
  
  .speaker-list-wrap {
    margin-top: 70px;
  }
  
  .speaker-list {
    margin: 0;
    padding: 0;
    
    li {
      cursor: pointer;
      float: left;
      list-style-type: none;
      width: 212px;
      height: 212px;
      position:relative;
      
      img {
        width: 100%;
        height: 100%;
      }
      
      .speaker-item-content {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        
        &.show {
          display: block;
        }
        
        .speaker-item-content-wrap {
          position: relative;
          text-align: center;
          margin: 60px auto 0;
          width: 80%;
          color: #fff;
          
          h3 {
            position: relative;
            font-size: 1.25rem;
            font-weight: bold;
            
            &:after {
              position: absolute;
              left: 76px;
              bottom: -12px;
              text-align: center;
              content: "";
              width: 22px;
              height: 1px;
              background-color: #fff;
            }
          }
          p {
            margin: 20px 0 10px;
            font-size: 0.9rem;
            font-weight: 500;
          }
          
          .speaker-item-subtitle {
            font-size: 0.8rem;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            max-width: 100%;
          }
        }
      }
    }

    @media all and (min-width:768px) and (max-width:1023px) {
      li {
        width: 25% !important;
        height: auto !important
      }
    }
    @media all and (max-width:767px) {
      li {
        width: 50% !important;
        height: auto !important
      }
    }

  }
`;

const SpeakerDetailWrapStyled = styled.div`
  min-width: 65%;
  margin: 100px;

  @media all and (max-width:767px) {
    margin: 10px !important;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
    font-size: 2rem;
    font-weight: bold;
  }
  
  .speaker-detail-info {
    padding: 20px 0 40px;
    position: relative;
    
    &:after {
      position: absolute;
      bottom: 10px;
      content: " ";
      width: 100%;
      height: 2px;
      background-color: #070707;
    }
  }
  
  .speaker-detail {
    display: flex;

    @media all and (max-width:767px) {
      display: block;
    }
    .speaker-detail-img {
      margin-right: 20px;
    }
  }
  
  .close-button-wrap {
    margin-top: 80px;
    text-align: center;
    
    .btn-close-modal {
      padding: 10px 40px;
      border: 1px solid #070707;
      min-width: 100px;
    }
  }
`;

export {
  SpeakersWrapStyled,
  SpeakerDetailWrapStyled,
};
