import styled from 'styled-components';

const CommonDividerStyled = styled.div`
  width: 70px;
  height: 4px;
  background-color: #303030;
  margin: 0 auto;
`;


export {
  CommonDividerStyled,
};
