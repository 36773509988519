/**
 * 제작 : emirue
 * 날짜 : 2022/03/07
 * 내용 : 강연자 상세
 */

import React from "react";
import PropTypes from "prop-types";
import {SpeakerDetailWrapStyled} from "../../../stylesheets/speakers";

function SpeakerDetail({ item, onClose }) {
  return (
    <SpeakerDetailWrapStyled>
      <div className="close" onClick={onClose}>
        X
      </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="speaker-detail-info">
              <h1 className="speaker-name">{item.name}</h1>
              <div className="speaker-detail-title" dangerouslySetInnerHTML={{__html: item.subtitle}}/>
            </div>
            <div className="speaker-detail">
              <div className="speaker-detail-img">
                <img src={item.url} alt={item.name} />
              </div>
              <div className="speaker-detail-text">
                <div className="speaker-detail-text-content" dangerouslySetInnerHTML={{__html: item.content}} />
              </div>
            </div>
          </div>
        </div>
        <div className="row close-button-wrap">
          <div className="col">
            <button className="btn btn-close-modal" onClick={onClose}>CLOSE</button>
          </div>
        </div>
      </div>
    </SpeakerDetailWrapStyled>
  );
}

SpeakerDetail.propTypes = {
  item: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
SpeakerDetail.defaultProps = {};

export default SpeakerDetail;
