import styled from 'styled-components';

const AboutWrapStyled = styled.section`
  background-color: #fff;
  padding-top: 100px;
  
  .about-title {
    h1 {
      font-size: 2rem;
    }
    
    h2 {
      font-size: 2.5rem;
      font-weight: bold;
    }
  }
  
  .about-contents {
    margin-top: 70px;
  }
  
  .about-overview-wrapper {
    margin-top: 70px;
    text-align: center;
    
    h1 {
      font-size: 2.5rem;
      font-weight: bold;
    }
    
    .about-overview-table-wrapper {
      margin: 50px auto 0;
      text-align: center;
      border-top: 6px solid #070707;
      padding-top: 2px;
      width: 100%;
      
      @media all and (min-width:1024px) {
        width: 70%;
      }
      
      .about-overview-table {
        width: 100%;
        border-top: 2px solid #070707 !important;
        
        .table {
          tbody {
            tr {
              th {
                position: relative;
                font-size: 1.5rem;
                font-weight: bold;
                &:after {
                  position: absolute;
                  top: 14px;
                  right: 0;
                  height: 20px;
                  border-right: 1px solid #d6d6d6;
                  content: "";
                }
              }
              
              td {
                font-size: 1.25rem;
                text-align: left;
                padding-left: 36px;
              }
            }
          }
        }
      }
    }
  }
`;


export {
  AboutWrapStyled,
};
